.button-row {
  display: flex; /* Flex container olarak ayarla */
  justify-content: center; /* İçerikleri yatay olarak ortala */
  align-items: center; /* İçerikleri dikey olarak ortala */
  gap: 10px; /* Butonlar arasında boşluk */
  font-size: 20px;
  transition: transform 0.3s ease;
  top: 120px;
  z-index: 55;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Opsiyonel gölgelendirme */
}

@media (max-width: 768px) {
  .button-row {
    font-size: 12px;
    top: 88px;
    z-index: 55;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Opsiyonel gölgelendirme */
    transition: opacity 0.5s, height 0.5s, padding 0.5s;
    height: 0;
    opacity: 1;
  }

  .button-row.visible {
    height: 60px; /* Varsayılan yüksekliği belirle */
  }
}
@media (max-width: 530px) {
  .button-row.visible {
    height: 40px; /* Varsayılan yüksekliği belirle */
  }
}
@media (max-width: 365px) {
  .button-row {
    font-size: 12px;
    top: 100px;
    z-index: 55;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Opsiyonel gölgelendirme */
  }
  .button-row.visible {
    height: 100px; /* Varsayılan yüksekliği belirle */
  }
}

.fixed {
  position: fixed;
}


@media (min-width: 768px) {
  .fixed {
    left: 30%;
    right: 30%;
  }
}

@media (max-width: 1600px) {
  .fixed {
    left: 20%;
    right: 20%;
  }
}

@media (max-width: 768px) {
  .fixed {
    left: 5%;
    right: 5%;
  }
}

.autoScaleButton {
  display: block; /* Blok öğe olarak ayarla */
  margin-left: 10px;
  background-color: #388e3c; /* Daha koyu yeşil arka plan */
  color: #ffffff; /* Beyaz yazı rengi, yeterli kontrasta sahip olmalı */
  padding: 10px 20px; /* İç boşluk */
  border: none; /* Kenarlık kaldırıldı */
  cursor: pointer; /* İmleç buton üzerine geldiğinde el simgesi */
  border-radius: 5px; /* Köşeleri yuvarlaklaştır */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2); /* Gölge efekti */
  margin: 10px auto; /* Dış boşluk, yatayda otomatik ortalamak için */
  padding-bottom: 10px; /* Dış boşluk */
}

.autoScaleButton:hover {
  background-color: #2e7d32; /* Buton üzerine gelindiğinde daha koyu arka plan rengi */
}

/* Image container styles */
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: auto; /* Ortalanmasını sağlamak için eklendi */
}

.image-anasayfa {
  margin: 10px;
  text-align: center;
  box-sizing: border-box; /* Padding ve border genişliği toplam genişliğe dahil edilir */
}

.image-anasayfa img {
  max-width: 100%; /* Made images responsive */
  height: auto;
}

.image-anasayfa p {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center; /* İçerik ögelerini dikey olarak ortalar */
  max-width: 910px;
}

@media (min-width: 768px) {
  .hisseYorumSpan {
    font-size: 20px;
  }
}

.image-container p {
  padding-left: 10px;
  padding-right: 10px;

  margin: 10px;
}

@media (max-width: 768px) {
  .anasayfa-giris {
    display: none;
  }
}
